import { Helmet } from "react-helmet";
import SEO from "../../components/seo";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { getShopifyStoreByCountry } from "../../../locale-shopifies";
import ALButton from "../../components/al_components/ALButton";
// temporary styles
import * as HelperStyles from "../temp_style/tools/_helpers.module.scss";
import * as Styles from "./returns.module.scss";

function ReturnsPage({ path }) {
  const dict = useLocalizedSentenceDict();
  const countryCode = useCurrentCountryCode();
  return (
    <>
      <SEO
        title="Exchanges and Returns - Ana Luisa"
        description="Discover Ana Luisa's hassle-free return process and make returning items a breeze. Our step-by-step guide ensures a seamless experience, allowing you to easily return any purchase. Shop with confidence at analuisa.com and enjoy our customer-friendly return policy today!"
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <main className={Styles.returns}>
        <div className={`${Styles.returns__container} ${HelperStyles.container}`}>
          <section className={Styles.returns__info}>
            <h1 className="al_h2">{dict.get("Exchanges and Returns")}</h1>
            <p className="al_p">
              {dict.get("We get it. Sometimes, things just don't work out")}!
              <br />
              <br />
              {dict.get(
                "Not to worry – we are here to help you find something new. To start your exchange or return, please select from the options below."
              )}
            </p>
            <a href="https://returns.analuisa.com/#/" className={Styles.returns__info__link}>
              <ALButton fullWidth>{dict.get("US CUSTOMERS")}</ALButton>
            </a>
            <a
              href="https://web.global-e.com/returns/portal/mZYA"
              className={Styles.returns__info__link}
            >
              <ALButton fullWidth>{dict.get("INTERNATIONAL CUSTOMERS")}</ALButton>
            </a>
          </section>

          <section className={Styles.returns__policy}>
            <div className={Styles.returns__policy__title}>
              <h2 className="al_p al_p--bold al_p--cap">{dict.get("Exchange & Return Policy")}</h2>
            </div>
            <div className={Styles.returns__policy__wrap}>
              <div className="policies">
                <dl>
                  <dd className="al_p">
                    {dict.get(
                      "We accept exchanges and returns on all products with original tags and packaging, both online and in store, within 30 days of delivery. All returns are subject to a return shipping fee of $5.99, which will be deducted from the total refund amount. As a little something extra, we offer 20% bonus store credit with every exchange (valid for US customers only). Please note orders placed through TikTok are not eligible for exchanges."
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt className="al_p al_p--bold">{dict.get("Final Sale Products")}</dt>
                  <dd className="al_p">
                    {dict.get(
                      "All Last Chance products and engraved jewelry are final sale at the time of purchase, and cannot be exchanged or returned. Additionally, the following products are final sale: Chain Extender, Earring Backs, Earring Back Lifters, Gift Box and Mystery products."
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt className="al_p al_p--bold">{dict.get("Jewelry Bundles")}</dt>
                  <dd className="al_p">
                    {dict.get(
                      "If you purchased a pre-kitted bundle, you will have the option to return the entire bundle or individual pieces from the bundle. When returning individual pieces, we will issue a proportionate refund back to your original payment method."
                    )}
                  </dd>
                  <dd className="al_p">
                    {dict.get(
                      "If you purchased a gift set, you must return all products in the set to receive a refund."
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt className="al_p al_p--bold">{dict.get("International Returns")}</dt>
                  <dd className="al_p">
                    {dict.get(
                      "Please allow up to 30 days for our team to process refunds on any international orders."
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt className="al_p al_p--bold">{dict.get("EU Returns")}</dt>
                  <dd className="al_p">
                    {dict.get(
                      "If your order number begins with an E, please contact our team directly so we may assist with your return."
                    )}
                  </dd>
                </dl>
                <dl>
                  <dt className="al_p al_p--bold">{dict.get("Need help?")}</dt>
                  <dd className="al_p">
                    Visit our{" "}
                    <a
                      href={getShopifyStoreByCountry(countryCode)?.knowledgeBase}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "underline" }}
                    >
                      FAQs page
                    </a>{" "}
                    or{" "}
                    <a href="mailto:love@analuisa.com" style={{ textDecoration: "underline" }}>
                      send us an email
                    </a>
                    .
                  </dd>
                </dl>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default ReturnsPage;
